/****************************************************************************************
     DO NOT ADD COMPONENTS IN THIS FILE
     ADD YOUR .SVG IN common/tasks/image-lib/src FOLDER
     THEN RUN npm run image-lib COMMAND
 ****************************************************************************************/
import React, { SVGProps } from 'react';

import { InstantPanel } from 'components/Icons/InstantPanel';
import { VoucherUser } from 'components/Icons/VoucherUser';
import { VoucherLogo } from 'components/Icons/VoucherLogo';
import { VoucherDocument } from 'components/Icons/VoucherDocument';
import { Vector } from 'components/Icons/Vector';
import { User } from 'components/Icons/User';
import { Tv } from 'components/Icons/Tv';
import { Trophy } from 'components/Icons/Trophy';
import { Trip } from 'components/Icons/Trip';
import { TrashCan } from 'components/Icons/TrashCan';
import { Ticket } from 'components/Icons/Ticket';
import { ThreeDots } from 'components/Icons/ThreeDots';
import { Success } from 'components/Icons/Success';
import { Streaming } from 'components/Icons/Streaming';
import { Stats } from 'components/Icons/Stats';
import { Star } from 'components/Icons/Star';
import { StarOutline } from 'components/Icons/StarOutline';
import { StadiaController } from 'components/Icons/StadiaController';
import { SpidLogo } from 'components/Icons/SpidLogo';
import { SnaiRunner } from 'components/Icons/SnaiRunner';
import { SnaiPicto } from 'components/Icons/SnaiPicto';
import { SnaiLogo } from 'components/Icons/SnaiLogo';
import { SnaiLogoShort } from 'components/Icons/SnaiLogoShort';
import { Smartphone } from 'components/Icons/Smartphone';
import { SmartToy } from 'components/Icons/SmartToy';
import { Slots } from 'components/Icons/Slots';
import { Shield } from 'components/Icons/Shield';
import { Share } from 'components/Icons/Share';
import { Search } from 'components/Icons/Search';
import { SearchOff } from 'components/Icons/SearchOff';
import { RoundQuestion } from 'components/Icons/RoundQuestion';
import { RoundQuestion32 } from 'components/Icons/RoundQuestion32';
import { Ribbon } from 'components/Icons/Ribbon';
import { ReloadSpin } from 'components/Icons/ReloadSpin';
import { RegnoUnito } from 'components/Icons/RegnoUnito';
import { Radio } from 'components/Icons/Radio';
import { Questions } from 'components/Icons/Questions';
import { Quadri } from 'components/Icons/Quadri';
import { Programmed } from 'components/Icons/Programmed';
import { Printer } from 'components/Icons/Printer';
import { PreferredOn } from 'components/Icons/PreferredOn';
import { PreferredOff } from 'components/Icons/PreferredOff';
import { PreferredOffBck } from 'components/Icons/PreferredOffBck';
import { PointHamburger } from 'components/Icons/PointHamburger';
import { Plus } from 'components/Icons/Plus';
import { PlusCircle } from 'components/Icons/PlusCircle';
import { Player } from 'components/Icons/Player';
import { PlayStroke } from 'components/Icons/PlayStroke';
import { Pinlocation } from 'components/Icons/Pinlocation';
import { Pin } from 'components/Icons/Pin';
import { Pig } from 'components/Icons/Pig';
import { Picche } from 'components/Icons/Picche';
import { Pencil } from 'components/Icons/Pencil';
import { PattinaggioVelocita } from 'components/Icons/PattinaggioVelocita';
import { NewLight } from 'components/Icons/NewLight';
import { NewDark } from 'components/Icons/NewDark';
import { NazioneUs } from 'components/Icons/NazioneUs';
import { NazioneSa } from 'components/Icons/NazioneSa';
import { NazionePh } from 'components/Icons/NazionePh';
import { NazioneLb } from 'components/Icons/NazioneLb';
import { NazioneKr } from 'components/Icons/NazioneKr';
import { NazioneKe } from 'components/Icons/NazioneKe';
import { NazioneJp } from 'components/Icons/NazioneJp';
import { NazioneJm } from 'components/Icons/NazioneJm';
import { NazioneIt } from 'components/Icons/NazioneIt';
import { NazioneIn } from 'components/Icons/NazioneIn';
import { NazioneIe } from 'components/Icons/NazioneIe';
import { NazioneHr } from 'components/Icons/NazioneHr';
import { NazioneGr } from 'components/Icons/NazioneGr';
import { NazioneGb } from 'components/Icons/NazioneGb';
import { NazioneFr } from 'components/Icons/NazioneFr';
import { NazioneFi } from 'components/Icons/NazioneFi';
import { NazioneEs } from 'components/Icons/NazioneEs';
import { NazioneEe } from 'components/Icons/NazioneEe';
import { NazioneDk } from 'components/Icons/NazioneDk';
import { NazioneDe } from 'components/Icons/NazioneDe';
import { NazioneCy } from 'components/Icons/NazioneCy';
import { NazioneCo } from 'components/Icons/NazioneCo';
import { NazioneCn } from 'components/Icons/NazioneCn';
import { NazioneCl } from 'components/Icons/NazioneCl';
import { NazioneCa } from 'components/Icons/NazioneCa';
import { NazioneBr } from 'components/Icons/NazioneBr';
import { NazioneBh } from 'components/Icons/NazioneBh';
import { NazioneBe } from 'components/Icons/NazioneBe';
import { NazioneBb } from 'components/Icons/NazioneBb';
import { NazioneAz } from 'components/Icons/NazioneAz';
import { NazioneAu } from 'components/Icons/NazioneAu';
import { NazioneAt } from 'components/Icons/NazioneAt';
import { NazioneAr } from 'components/Icons/NazioneAr';
import { NazioneAg } from 'components/Icons/NazioneAg';
import { NazioneAe } from 'components/Icons/NazioneAe';
import { Minus } from 'components/Icons/Minus';
import { MinusCircle } from 'components/Icons/MinusCircle';
import { MeteoVento } from 'components/Icons/MeteoVento';
import { MeteoVariabile } from 'components/Icons/MeteoVariabile';
import { MeteoPioggia } from 'components/Icons/MeteoPioggia';
import { MeteoNebbia } from 'components/Icons/MeteoNebbia';
import { MeteoFoschia } from 'components/Icons/MeteoFoschia';
import { MeteoCoperto } from 'components/Icons/MeteoCoperto';
import { MeteoBello } from 'components/Icons/MeteoBello';
import { Message } from 'components/Icons/Message';
import { MessageCenterIco98 } from 'components/Icons/MessageCenterIco98';
import { MessageCenterIco91 } from 'components/Icons/MessageCenterIco91';
import { MessageCenterIco87 } from 'components/Icons/MessageCenterIco87';
import { MessageCenterIco85 } from 'components/Icons/MessageCenterIco85';
import { MessageCenterIco82 } from 'components/Icons/MessageCenterIco82';
import { MessageCenterIco8 } from 'components/Icons/MessageCenterIco8';
import { MessageCenterIco79 } from 'components/Icons/MessageCenterIco79';
import { MessageCenterIco76 } from 'components/Icons/MessageCenterIco76';
import { MessageCenterIco74 } from 'components/Icons/MessageCenterIco74';
import { MessageCenterIco73 } from 'components/Icons/MessageCenterIco73';
import { MessageCenterIco71 } from 'components/Icons/MessageCenterIco71';
import { MessageCenterIco69 } from 'components/Icons/MessageCenterIco69';
import { MessageCenterIco63 } from 'components/Icons/MessageCenterIco63';
import { MessageCenterIco61 } from 'components/Icons/MessageCenterIco61';
import { MessageCenterIco59 } from 'components/Icons/MessageCenterIco59';
import { MessageCenterIco57 } from 'components/Icons/MessageCenterIco57';
import { MessageCenterIco55 } from 'components/Icons/MessageCenterIco55';
import { MessageCenterIco53 } from 'components/Icons/MessageCenterIco53';
import { MessageCenterIco52 } from 'components/Icons/MessageCenterIco52';
import { MessageCenterIco49 } from 'components/Icons/MessageCenterIco49';
import { MessageCenterIco47 } from 'components/Icons/MessageCenterIco47';
import { MessageCenterIco43 } from 'components/Icons/MessageCenterIco43';
import { MessageCenterIco421 } from 'components/Icons/MessageCenterIco421';
import { MessageCenterIco420 } from 'components/Icons/MessageCenterIco420';
import { MessageCenterIco42 } from 'components/Icons/MessageCenterIco42';
import { MessageCenterIco419 } from 'components/Icons/MessageCenterIco419';
import { MessageCenterIco418 } from 'components/Icons/MessageCenterIco418';
import { MessageCenterIco417 } from 'components/Icons/MessageCenterIco417';
import { MessageCenterIco416 } from 'components/Icons/MessageCenterIco416';
import { MessageCenterIco415 } from 'components/Icons/MessageCenterIco415';
import { MessageCenterIco414 } from 'components/Icons/MessageCenterIco414';
import { MessageCenterIco413 } from 'components/Icons/MessageCenterIco413';
import { MessageCenterIco412 } from 'components/Icons/MessageCenterIco412';
import { MessageCenterIco411 } from 'components/Icons/MessageCenterIco411';
import { MessageCenterIco410 } from 'components/Icons/MessageCenterIco410';
import { MessageCenterIco41 } from 'components/Icons/MessageCenterIco41';
import { MessageCenterIco409 } from 'components/Icons/MessageCenterIco409';
import { MessageCenterIco408 } from 'components/Icons/MessageCenterIco408';
import { MessageCenterIco407 } from 'components/Icons/MessageCenterIco407';
import { MessageCenterIco406 } from 'components/Icons/MessageCenterIco406';
import { MessageCenterIco405 } from 'components/Icons/MessageCenterIco405';
import { MessageCenterIco404 } from 'components/Icons/MessageCenterIco404';
import { MessageCenterIco403 } from 'components/Icons/MessageCenterIco403';
import { MessageCenterIco402 } from 'components/Icons/MessageCenterIco402';
import { MessageCenterIco401 } from 'components/Icons/MessageCenterIco401';
import { MessageCenterIco400 } from 'components/Icons/MessageCenterIco400';
import { MessageCenterIco40 } from 'components/Icons/MessageCenterIco40';
import { MessageCenterIco399 } from 'components/Icons/MessageCenterIco399';
import { MessageCenterIco398 } from 'components/Icons/MessageCenterIco398';
import { MessageCenterIco397 } from 'components/Icons/MessageCenterIco397';
import { MessageCenterIco396 } from 'components/Icons/MessageCenterIco396';
import { MessageCenterIco395 } from 'components/Icons/MessageCenterIco395';
import { MessageCenterIco394 } from 'components/Icons/MessageCenterIco394';
import { MessageCenterIco393 } from 'components/Icons/MessageCenterIco393';
import { MessageCenterIco392 } from 'components/Icons/MessageCenterIco392';
import { MessageCenterIco391 } from 'components/Icons/MessageCenterIco391';
import { MessageCenterIco390 } from 'components/Icons/MessageCenterIco390';
import { MessageCenterIco389 } from 'components/Icons/MessageCenterIco389';
import { MessageCenterIco388 } from 'components/Icons/MessageCenterIco388';
import { MessageCenterIco387 } from 'components/Icons/MessageCenterIco387';
import { MessageCenterIco386 } from 'components/Icons/MessageCenterIco386';
import { MessageCenterIco385 } from 'components/Icons/MessageCenterIco385';
import { MessageCenterIco384 } from 'components/Icons/MessageCenterIco384';
import { MessageCenterIco383 } from 'components/Icons/MessageCenterIco383';
import { MessageCenterIco382 } from 'components/Icons/MessageCenterIco382';
import { MessageCenterIco381 } from 'components/Icons/MessageCenterIco381';
import { MessageCenterIco380 } from 'components/Icons/MessageCenterIco380';
import { MessageCenterIco379 } from 'components/Icons/MessageCenterIco379';
import { MessageCenterIco378 } from 'components/Icons/MessageCenterIco378';
import { MessageCenterIco377 } from 'components/Icons/MessageCenterIco377';
import { MessageCenterIco376 } from 'components/Icons/MessageCenterIco376';
import { MessageCenterIco375 } from 'components/Icons/MessageCenterIco375';
import { MessageCenterIco374 } from 'components/Icons/MessageCenterIco374';
import { MessageCenterIco373 } from 'components/Icons/MessageCenterIco373';
import { MessageCenterIco372 } from 'components/Icons/MessageCenterIco372';
import { MessageCenterIco371 } from 'components/Icons/MessageCenterIco371';
import { MessageCenterIco370 } from 'components/Icons/MessageCenterIco370';
import { MessageCenterIco369 } from 'components/Icons/MessageCenterIco369';
import { MessageCenterIco368 } from 'components/Icons/MessageCenterIco368';
import { MessageCenterIco367 } from 'components/Icons/MessageCenterIco367';
import { MessageCenterIco366 } from 'components/Icons/MessageCenterIco366';
import { MessageCenterIco365 } from 'components/Icons/MessageCenterIco365';
import { MessageCenterIco364 } from 'components/Icons/MessageCenterIco364';
import { MessageCenterIco363 } from 'components/Icons/MessageCenterIco363';
import { MessageCenterIco362 } from 'components/Icons/MessageCenterIco362';
import { MessageCenterIco36 } from 'components/Icons/MessageCenterIco36';
import { MessageCenterIco329 } from 'components/Icons/MessageCenterIco329';
import { MessageCenterIco319 } from 'components/Icons/MessageCenterIco319';
import { MessageCenterIco317 } from 'components/Icons/MessageCenterIco317';
import { MessageCenterIco314 } from 'components/Icons/MessageCenterIco314';
import { MessageCenterIco313 } from 'components/Icons/MessageCenterIco313';
import { MessageCenterIco309 } from 'components/Icons/MessageCenterIco309';
import { MessageCenterIco308 } from 'components/Icons/MessageCenterIco308';
import { MessageCenterIco303 } from 'components/Icons/MessageCenterIco303';
import { MessageCenterIco301 } from 'components/Icons/MessageCenterIco301';
import { MessageCenterIco298 } from 'components/Icons/MessageCenterIco298';
import { MessageCenterIco294 } from 'components/Icons/MessageCenterIco294';
import { MessageCenterIco291 } from 'components/Icons/MessageCenterIco291';
import { MessageCenterIco288 } from 'components/Icons/MessageCenterIco288';
import { MessageCenterIco283 } from 'components/Icons/MessageCenterIco283';
import { MessageCenterIco28 } from 'components/Icons/MessageCenterIco28';
import { MessageCenterIco276 } from 'components/Icons/MessageCenterIco276';
import { MessageCenterIco271 } from 'components/Icons/MessageCenterIco271';
import { MessageCenterIco264 } from 'components/Icons/MessageCenterIco264';
import { MessageCenterIco259 } from 'components/Icons/MessageCenterIco259';
import { MessageCenterIco254 } from 'components/Icons/MessageCenterIco254';
import { MessageCenterIco252 } from 'components/Icons/MessageCenterIco252';
import { MessageCenterIco25 } from 'components/Icons/MessageCenterIco25';
import { MessageCenterIco248 } from 'components/Icons/MessageCenterIco248';
import { MessageCenterIco245 } from 'components/Icons/MessageCenterIco245';
import { MessageCenterIco244 } from 'components/Icons/MessageCenterIco244';
import { MessageCenterIco241 } from 'components/Icons/MessageCenterIco241';
import { MessageCenterIco238 } from 'components/Icons/MessageCenterIco238';
import { MessageCenterIco235 } from 'components/Icons/MessageCenterIco235';
import { MessageCenterIco230 } from 'components/Icons/MessageCenterIco230';
import { MessageCenterIco225 } from 'components/Icons/MessageCenterIco225';
import { MessageCenterIco222 } from 'components/Icons/MessageCenterIco222';
import { MessageCenterIco217 } from 'components/Icons/MessageCenterIco217';
import { MessageCenterIco215 } from 'components/Icons/MessageCenterIco215';
import { MessageCenterIco210 } from 'components/Icons/MessageCenterIco210';
import { MessageCenterIco21 } from 'components/Icons/MessageCenterIco21';
import { MessageCenterIco208 } from 'components/Icons/MessageCenterIco208';
import { MessageCenterIco205 } from 'components/Icons/MessageCenterIco205';
import { MessageCenterIco201 } from 'components/Icons/MessageCenterIco201';
import { MessageCenterIco199 } from 'components/Icons/MessageCenterIco199';
import { MessageCenterIco196 } from 'components/Icons/MessageCenterIco196';
import { MessageCenterIco190 } from 'components/Icons/MessageCenterIco190';
import { MessageCenterIco188 } from 'components/Icons/MessageCenterIco188';
import { MessageCenterIco186 } from 'components/Icons/MessageCenterIco186';
import { MessageCenterIco180 } from 'components/Icons/MessageCenterIco180';
import { MessageCenterIco173 } from 'components/Icons/MessageCenterIco173';
import { MessageCenterIco17 } from 'components/Icons/MessageCenterIco17';
import { MessageCenterIco167 } from 'components/Icons/MessageCenterIco167';
import { MessageCenterIco163 } from 'components/Icons/MessageCenterIco163';
import { MessageCenterIco159 } from 'components/Icons/MessageCenterIco159';
import { MessageCenterIco158 } from 'components/Icons/MessageCenterIco158';
import { MessageCenterIco152 } from 'components/Icons/MessageCenterIco152';
import { MessageCenterIco151 } from 'components/Icons/MessageCenterIco151';
import { MessageCenterIco147 } from 'components/Icons/MessageCenterIco147';
import { MessageCenterIco146 } from 'components/Icons/MessageCenterIco146';
import { MessageCenterIco141 } from 'components/Icons/MessageCenterIco141';
import { MessageCenterIco140 } from 'components/Icons/MessageCenterIco140';
import { MessageCenterIco134 } from 'components/Icons/MessageCenterIco134';
import { MessageCenterIco129 } from 'components/Icons/MessageCenterIco129';
import { MessageCenterIco120 } from 'components/Icons/MessageCenterIco120';
import { MessageCenterIco12 } from 'components/Icons/MessageCenterIco12';
import { MessageCenterIco11 } from 'components/Icons/MessageCenterIco11';
import { MessageCenterIco109 } from 'components/Icons/MessageCenterIco109';
import { MessageCenterIco105 } from 'components/Icons/MessageCenterIco105';
import { MessageCenterIco102 } from 'components/Icons/MessageCenterIco102';
import { MessageCenterIco101 } from 'components/Icons/MessageCenterIco101';
import { Menu } from 'components/Icons/Menu';
import { Media } from 'components/Icons/Media';
import { Medal } from 'components/Icons/Medal';
import { MatchLive } from 'components/Icons/MatchLive';
import { Live_hb } from 'components/Icons/Live_hb';
import { Live } from 'components/Icons/Live';
import { ListMenu } from 'components/Icons/ListMenu';
import { LinkExternal } from 'components/Icons/LinkExternal';
import { LightMode } from 'components/Icons/LightMode';
import { LegendaTrisLight } from 'components/Icons/LegendaTrisLight';
import { LegendaTrisDark } from 'components/Icons/LegendaTrisDark';
import { LegendaTqqLight } from 'components/Icons/LegendaTqqLight';
import { LegendaTqqDark } from 'components/Icons/LegendaTqqDark';
import { LegendaTotLight } from 'components/Icons/LegendaTotLight';
import { LegendaTotDark } from 'components/Icons/LegendaTotDark';
import { LegendaInpagamentoLight } from 'components/Icons/LegendaInpagamentoLight';
import { LegendaInpagamentoDark } from 'components/Icons/LegendaInpagamentoDark';
import { LegendaInattesaLight } from 'components/Icons/LegendaInattesaLight';
import { LegendaInattesaDark } from 'components/Icons/LegendaInattesaDark';
import { LegendaChiusaLight } from 'components/Icons/LegendaChiusaLight';
import { LegendaChiusaDark } from 'components/Icons/LegendaChiusaDark';
import { LegendaApertaLight } from 'components/Icons/LegendaApertaLight';
import { LegendaApertaDark } from 'components/Icons/LegendaApertaDark';
import { LegendaAnnullataLight } from 'components/Icons/LegendaAnnullataLight';
import { LegendaAnnullataDark } from 'components/Icons/LegendaAnnullataDark';
import { LaptopMac } from 'components/Icons/LaptopMac';
import { Italia } from 'components/Icons/Italia';
import { Info } from 'components/Icons/Info';
import { InfoYellow } from 'components/Icons/InfoYellow';
import { InfoSolid } from 'components/Icons/InfoSolid';
import { Image } from 'components/Icons/Image';
import { HeartOutline } from 'components/Icons/HeartOutline';
import { HappybetLogo } from 'components/Icons/HappybetLogo';
import { GotoArrow } from 'components/Icons/GotoArrow';
import { Gift } from 'components/Icons/Gift';
import { Gear } from 'components/Icons/Gear';
import { GameSlash } from 'components/Icons/GameSlash';
import { GameAutoexclusion } from 'components/Icons/GameAutoexclusion';
import { FullPinned } from 'components/Icons/FullPinned';
import { FullPin } from 'components/Icons/FullPin';
import { Form } from 'components/Icons/Form';
import { Fiori } from 'components/Icons/Fiori';
import { Filter } from 'components/Icons/Filter';
import { FilterVertical } from 'components/Icons/FilterVertical';
import { FerriNone } from 'components/Icons/FerriNone';
import { FerriFull } from 'components/Icons/FerriFull';
import { FerriDietro } from 'components/Icons/FerriDietro';
import { FerriDavanti } from 'components/Icons/FerriDavanti';
import { Eye } from 'components/Icons/Eye';
import { EyeOff } from 'components/Icons/EyeOff';
import { Exit } from 'components/Icons/Exit';
import { ExclamationCircle } from 'components/Icons/ExclamationCircle';
import { EclamationMarkOutline } from 'components/Icons/EclamationMarkOutline';
import { Duplicate } from 'components/Icons/Duplicate';
import { Download } from 'components/Icons/Download';
import { DownloadVirtual } from 'components/Icons/DownloadVirtual';
import { DownloadApple } from 'components/Icons/DownloadApple';
import { DoubleChevronRight } from 'components/Icons/DoubleChevronRight';
import { DisciplinaTiroConVolo } from 'components/Icons/DisciplinaTiroConVolo';
import { DisciplinaSportGaelici } from 'components/Icons/DisciplinaSportGaelici';
import { DisciplinaPattinaggioFigurato } from 'components/Icons/DisciplinaPattinaggioFigurato';
import { DisciplinaManifestazioniSpettacolo } from 'components/Icons/DisciplinaManifestazioniSpettacolo';
import { DisciplinaHockeyInliine } from 'components/Icons/DisciplinaHockeyInliine';
import { DisciplinaFallback } from 'components/Icons/DisciplinaFallback';
import { DisciplinaEsportsWorldOfWarcraft } from 'components/Icons/DisciplinaEsportsWorldOfWarcraft';
import { DisciplinaEsportsStarcraft2 } from 'components/Icons/DisciplinaEsportsStarcraft2';
import { DisciplinaEsportsNBA2KLeague } from 'components/Icons/DisciplinaEsportsNBA2KLeague';
import { DisciplinaEsportsMotociclismo } from 'components/Icons/DisciplinaEsportsMotociclismo';
import { DisciplinaEsportsFormulaE } from 'components/Icons/DisciplinaEsportsFormulaE';
import { Disciplina99 } from 'components/Icons/Disciplina99';
import { Disciplina98 } from 'components/Icons/Disciplina98';
import { Disciplina97 } from 'components/Icons/Disciplina97';
import { Disciplina96 } from 'components/Icons/Disciplina96';
import { Disciplina95 } from 'components/Icons/Disciplina95';
import { Disciplina94 } from 'components/Icons/Disciplina94';
import { Disciplina93 } from 'components/Icons/Disciplina93';
import { Disciplina92 } from 'components/Icons/Disciplina92';
import { Disciplina91 } from 'components/Icons/Disciplina91';
import { Disciplina90 } from 'components/Icons/Disciplina90';
import { Disciplina9 } from 'components/Icons/Disciplina9';
import { Disciplina89 } from 'components/Icons/Disciplina89';
import { Disciplina88 } from 'components/Icons/Disciplina88';
import { Disciplina87 } from 'components/Icons/Disciplina87';
import { Disciplina86 } from 'components/Icons/Disciplina86';
import { Disciplina85 } from 'components/Icons/Disciplina85';
import { Disciplina83 } from 'components/Icons/Disciplina83';
import { Disciplina82 } from 'components/Icons/Disciplina82';
import { Disciplina81 } from 'components/Icons/Disciplina81';
import { Disciplina80 } from 'components/Icons/Disciplina80';
import { Disciplina8 } from 'components/Icons/Disciplina8';
import { Disciplina79 } from 'components/Icons/Disciplina79';
import { Disciplina78 } from 'components/Icons/Disciplina78';
import { Disciplina77 } from 'components/Icons/Disciplina77';
import { Disciplina76 } from 'components/Icons/Disciplina76';
import { Disciplina75 } from 'components/Icons/Disciplina75';
import { Disciplina73 } from 'components/Icons/Disciplina73';
import { Disciplina71 } from 'components/Icons/Disciplina71';
import { Disciplina70 } from 'components/Icons/Disciplina70';
import { Disciplina7 } from 'components/Icons/Disciplina7';
import { Disciplina69 } from 'components/Icons/Disciplina69';
import { Disciplina68 } from 'components/Icons/Disciplina68';
import { Disciplina67 } from 'components/Icons/Disciplina67';
import { Disciplina66 } from 'components/Icons/Disciplina66';
import { Disciplina65 } from 'components/Icons/Disciplina65';
import { Disciplina64 } from 'components/Icons/Disciplina64';
import { Disciplina62 } from 'components/Icons/Disciplina62';
import { Disciplina61 } from 'components/Icons/Disciplina61';
import { Disciplina60 } from 'components/Icons/Disciplina60';
import { Disciplina6 } from 'components/Icons/Disciplina6';
import { Disciplina59 } from 'components/Icons/Disciplina59';
import { Disciplina58 } from 'components/Icons/Disciplina58';
import { Disciplina57 } from 'components/Icons/Disciplina57';
import { Disciplina56 } from 'components/Icons/Disciplina56';
import { Disciplina55 } from 'components/Icons/Disciplina55';
import { Disciplina54 } from 'components/Icons/Disciplina54';
import { Disciplina53 } from 'components/Icons/Disciplina53';
import { Disciplina52 } from 'components/Icons/Disciplina52';
import { Disciplina51 } from 'components/Icons/Disciplina51';
import { Disciplina50 } from 'components/Icons/Disciplina50';
import { Disciplina5 } from 'components/Icons/Disciplina5';
import { Disciplina49 } from 'components/Icons/Disciplina49';
import { Disciplina48 } from 'components/Icons/Disciplina48';
import { Disciplina47 } from 'components/Icons/Disciplina47';
import { Disciplina46 } from 'components/Icons/Disciplina46';
import { Disciplina45 } from 'components/Icons/Disciplina45';
import { Disciplina44 } from 'components/Icons/Disciplina44';
import { Disciplina43 } from 'components/Icons/Disciplina43';
import { Disciplina42 } from 'components/Icons/Disciplina42';
import { Disciplina41 } from 'components/Icons/Disciplina41';
import { Disciplina40 } from 'components/Icons/Disciplina40';
import { Disciplina39 } from 'components/Icons/Disciplina39';
import { Disciplina38 } from 'components/Icons/Disciplina38';
import { Disciplina37 } from 'components/Icons/Disciplina37';
import { Disciplina36 } from 'components/Icons/Disciplina36';
import { Disciplina35 } from 'components/Icons/Disciplina35';
import { Disciplina34 } from 'components/Icons/Disciplina34';
import { Disciplina33 } from 'components/Icons/Disciplina33';
import { Disciplina32 } from 'components/Icons/Disciplina32';
import { Disciplina31 } from 'components/Icons/Disciplina31';
import { Disciplina30 } from 'components/Icons/Disciplina30';
import { Disciplina3 } from 'components/Icons/Disciplina3';
import { Disciplina29 } from 'components/Icons/Disciplina29';
import { Disciplina28 } from 'components/Icons/Disciplina28';
import { Disciplina27 } from 'components/Icons/Disciplina27';
import { Disciplina24 } from 'components/Icons/Disciplina24';
import { Disciplina23 } from 'components/Icons/Disciplina23';
import { Disciplina22 } from 'components/Icons/Disciplina22';
import { Disciplina21 } from 'components/Icons/Disciplina21';
import { Disciplina20 } from 'components/Icons/Disciplina20';
import { Disciplina2 } from 'components/Icons/Disciplina2';
import { Disciplina19 } from 'components/Icons/Disciplina19';
import { Disciplina18 } from 'components/Icons/Disciplina18';
import { Disciplina17 } from 'components/Icons/Disciplina17';
import { Disciplina16 } from 'components/Icons/Disciplina16';
import { Disciplina159 } from 'components/Icons/Disciplina159';
import { Disciplina158 } from 'components/Icons/Disciplina158';
import { Disciplina157 } from 'components/Icons/Disciplina157';
import { Disciplina156 } from 'components/Icons/Disciplina156';
import { Disciplina155 } from 'components/Icons/Disciplina155';
import { Disciplina154 } from 'components/Icons/Disciplina154';
import { Disciplina152 } from 'components/Icons/Disciplina152';
import { Disciplina150 } from 'components/Icons/Disciplina150';
import { Disciplina15 } from 'components/Icons/Disciplina15';
import { Disciplina148 } from 'components/Icons/Disciplina148';
import { Disciplina147 } from 'components/Icons/Disciplina147';
import { Disciplina144 } from 'components/Icons/Disciplina144';
import { Disciplina143 } from 'components/Icons/Disciplina143';
import { Disciplina142 } from 'components/Icons/Disciplina142';
import { Disciplina140 } from 'components/Icons/Disciplina140';
import { Disciplina14 } from 'components/Icons/Disciplina14';
import { Disciplina138 } from 'components/Icons/Disciplina138';
import { Disciplina136 } from 'components/Icons/Disciplina136';
import { Disciplina135 } from 'components/Icons/Disciplina135';
import { Disciplina134 } from 'components/Icons/Disciplina134';
import { Disciplina132 } from 'components/Icons/Disciplina132';
import { Disciplina131 } from 'components/Icons/Disciplina131';
import { Disciplina130 } from 'components/Icons/Disciplina130';
import { Disciplina13 } from 'components/Icons/Disciplina13';
import { Disciplina129 } from 'components/Icons/Disciplina129';
import { Disciplina128 } from 'components/Icons/Disciplina128';
import { Disciplina127 } from 'components/Icons/Disciplina127';
import { Disciplina126 } from 'components/Icons/Disciplina126';
import { Disciplina125 } from 'components/Icons/Disciplina125';
import { Disciplina124 } from 'components/Icons/Disciplina124';
import { Disciplina123 } from 'components/Icons/Disciplina123';
import { Disciplina121 } from 'components/Icons/Disciplina121';
import { Disciplina120 } from 'components/Icons/Disciplina120';
import { Disciplina12 } from 'components/Icons/Disciplina12';
import { Disciplina119 } from 'components/Icons/Disciplina119';
import { Disciplina118 } from 'components/Icons/Disciplina118';
import { Disciplina117 } from 'components/Icons/Disciplina117';
import { Disciplina116 } from 'components/Icons/Disciplina116';
import { Disciplina115 } from 'components/Icons/Disciplina115';
import { Disciplina114 } from 'components/Icons/Disciplina114';
import { Disciplina112 } from 'components/Icons/Disciplina112';
import { Disciplina110 } from 'components/Icons/Disciplina110';
import { Disciplina11 } from 'components/Icons/Disciplina11';
import { Disciplina109 } from 'components/Icons/Disciplina109';
import { Disciplina108 } from 'components/Icons/Disciplina108';
import { Disciplina107 } from 'components/Icons/Disciplina107';
import { Disciplina106 } from 'components/Icons/Disciplina106';
import { Disciplina105 } from 'components/Icons/Disciplina105';
import { Disciplina104 } from 'components/Icons/Disciplina104';
import { Disciplina103 } from 'components/Icons/Disciplina103';
import { Disciplina1028 } from 'components/Icons/Disciplina1028';
import { Disciplina1027 } from 'components/Icons/Disciplina1027';
import { Disciplina1026 } from 'components/Icons/Disciplina1026';
import { Disciplina102 } from 'components/Icons/Disciplina102';
import { Disciplina1013 } from 'components/Icons/Disciplina1013';
import { Disciplina101 } from 'components/Icons/Disciplina101';
import { Disciplina1007 } from 'components/Icons/Disciplina1007';
import { Disciplina1004 } from 'components/Icons/Disciplina1004';
import { Disciplina1003 } from 'components/Icons/Disciplina1003';
import { Disciplina1001 } from 'components/Icons/Disciplina1001';
import { Disciplina100 } from 'components/Icons/Disciplina100';
import { Disciplina10 } from 'components/Icons/Disciplina10';
import { Disciplina1 } from 'components/Icons/Disciplina1';
import { Disciplina03 } from 'components/Icons/Disciplina03';
import { Disciplina02 } from 'components/Icons/Disciplina02';
import { Disciplina01 } from 'components/Icons/Disciplina01';
import { Disciplina003 } from 'components/Icons/Disciplina003';
import { Disciplina001 } from 'components/Icons/Disciplina001';
import { Disciplina00 } from 'components/Icons/Disciplina00';
import { Dices } from 'components/Icons/Dices';
import { Deposit } from 'components/Icons/Deposit';
import { Dashboard } from 'components/Icons/Dashboard';
import { DashboardUpdated } from 'components/Icons/DashboardUpdated';
import { DarkMode } from 'components/Icons/DarkMode';
import { Cup } from 'components/Icons/Cup';
import { Cuori } from 'components/Icons/Cuori';
import { ControllerOff } from 'components/Icons/ControllerOff';
import { ContractPdf } from 'components/Icons/ContractPdf';
import { Comment } from 'components/Icons/Comment';
import { Close } from 'components/Icons/Close';
import { CloseCircle } from 'components/Icons/CloseCircle';
import { CircleWarning } from 'components/Icons/CircleWarning';
import { ChewronUp } from 'components/Icons/ChewronUp';
import { ChewronRight } from 'components/Icons/ChewronRight';
import { ChewronLeft } from 'components/Icons/ChewronLeft';
import { ChewronDown } from 'components/Icons/ChewronDown';
import { Checkmark } from 'components/Icons/Checkmark';
import { Check } from 'components/Icons/Check';
import { CheckGreen } from 'components/Icons/CheckGreen';
import { Chat } from 'components/Icons/Chat';
import { Chart } from 'components/Icons/Chart';
import { Cavallo } from 'components/Icons/Cavallo';
import { CavalloParaocchi } from 'components/Icons/CavalloParaocchi';
import { CashMultiple } from 'components/Icons/CashMultiple';
import { Cancel } from 'components/Icons/Cancel';
import { CancelFull } from 'components/Icons/CancelFull';
import { Calendar } from 'components/Icons/Calendar';
import { Calculator } from 'components/Icons/Calculator';
import { CalculatorMd } from 'components/Icons/CalculatorMd';
import { Bonus } from 'components/Icons/Bonus';
import { BonusGrey } from 'components/Icons/BonusGrey';
import { AwesomeUser } from 'components/Icons/AwesomeUser';
import { Avatar } from 'components/Icons/Avatar';
import { Autoesclusion24h } from 'components/Icons/Autoesclusion24h';
import { Arrow } from 'components/Icons/Arrow';
import { ArrowUp } from 'components/Icons/ArrowUp';
import { ArrowIncrease } from 'components/Icons/ArrowIncrease';
import { ArrowIncreaseLight } from 'components/Icons/ArrowIncreaseLight';
import { ArrowDecrease } from 'components/Icons/ArrowDecrease';
import { ArrowDecreaseLight } from 'components/Icons/ArrowDecreaseLight';
import { ArrowBack } from 'components/Icons/ArrowBack';
import { Archive } from 'components/Icons/Archive';
import { Alert } from 'components/Icons/Alert';
import { AdmWhiteHorizontal } from 'components/Icons/AdmWhiteHorizontal';
import { AdmWhite18 } from 'components/Icons/AdmWhite18';
import { AdmColorfulHorizontal } from 'components/Icons/AdmColorfulHorizontal';
import { Acme } from 'components/Icons/Acme';
import { Account } from 'components/Icons/Account';
import { IconsEnum } from 'components/Icons';
import type { IconProps } from 'components/Icons';

export const Static = (props: IconProps): JSX.Element => {
  const { iconId, ...oth } = props;
  switch (iconId) {
    case IconsEnum.INSTANT_PANEL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <InstantPanel {...(oth as SVGProps<HTMLImageElement>)} />;
    }
    case IconsEnum.VOUCHER_USER: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <VoucherUser {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.VOUCHER_LOGO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <VoucherLogo {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.VOUCHER_DOCUMENT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <VoucherDocument {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.VECTOR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Vector {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.USER: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <User {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.TV: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Tv {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.TROPHY: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Trophy {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.TRIP: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Trip {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.TRASH_CAN: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <TrashCan {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.TICKET: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Ticket {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.THREE_DOTS: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ThreeDots {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SUCCESS: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Success {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.STREAMING: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Streaming {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.STATS: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Stats {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.STAR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Star {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.STAR_OUTLINE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <StarOutline {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.STADIA_CONTROLLER: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <StadiaController {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SPID_LOGO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <SpidLogo {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SNAI_RUNNER: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <SnaiRunner {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SNAI_PICTO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <SnaiPicto {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SNAI_LOGO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <SnaiLogo {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SNAI_LOGO_SHORT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <SnaiLogoShort {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SMARTPHONE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Smartphone {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SMART_TOY: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <SmartToy {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SLOTS: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Slots {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SHIELD: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Shield {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SHARE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Share {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SEARCH: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Search {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.SEARCH_OFF: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <SearchOff {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ROUND_QUESTION: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <RoundQuestion {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ROUND_QUESTION_32: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <RoundQuestion32 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.RIBBON: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Ribbon {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.RELOAD_SPIN: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ReloadSpin {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.REGNO_UNITO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <RegnoUnito {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.RADIO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Radio {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.QUESTIONS: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Questions {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.QUADRI: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Quadri {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PROGRAMMED: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Programmed {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PRINTER: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Printer {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PREFERRED_ON: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <PreferredOn {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PREFERRED_OFF: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <PreferredOff {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PREFERRED_OFF_BCK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <PreferredOffBck {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.POINT_HAMBURGER: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <PointHamburger {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PLUS: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Plus {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PLUS_CIRCLE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <PlusCircle {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PLAYER: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Player {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PLAY_STROKE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <PlayStroke {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PINLOCATION: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Pinlocation {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PIN: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Pin {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PIG: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Pig {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PICCHE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Picche {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PENCIL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Pencil {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.PATTINAGGIO_VELOCITA: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <PattinaggioVelocita {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NEW_LIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NewLight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NEW_DARK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NewDark {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_US: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneUs {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_SA: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneSa {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_PH: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazionePh {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_LB: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneLb {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_KR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneKr {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_KE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneKe {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_JP: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneJp {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_JM: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneJm {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_IT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneIt {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_IN: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneIn {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_IE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneIe {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_HR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneHr {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_GR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneGr {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_GB: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneGb {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_FR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneFr {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_FI: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneFi {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_ES: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneEs {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_EE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneEe {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_DK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneDk {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_DE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneDe {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_CY: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneCy {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_CO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneCo {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_CN: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneCn {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_CL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneCl {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_CA: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneCa {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_BR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneBr {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_BH: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneBh {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_BE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneBe {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_BB: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneBb {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_AZ: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneAz {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_AU: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneAu {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_AT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneAt {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_AR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneAr {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_AG: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneAg {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.NAZIONE_AE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <NazioneAe {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MINUS: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Minus {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MINUS_CIRCLE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MinusCircle {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.METEO_VENTO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MeteoVento {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.METEO_VARIABILE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MeteoVariabile {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.METEO_PIOGGIA: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MeteoPioggia {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.METEO_NEBBIA: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MeteoNebbia {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.METEO_FOSCHIA: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MeteoFoschia {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.METEO_COPERTO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MeteoCoperto {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.METEO_BELLO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MeteoBello {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Message {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_98: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco98 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_91: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco91 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_87: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco87 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_85: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco85 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_82: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco82 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_8: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco8 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_79: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco79 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_76: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco76 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_74: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco74 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_73: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco73 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_71: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco71 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_69: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco69 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_63: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco63 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_61: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco61 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_59: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco59 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_57: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco57 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_55: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco55 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_53: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco53 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_52: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco52 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_49: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco49 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_47: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco47 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_43: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco43 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_421: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco421 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_420: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco420 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_42: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco42 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_419: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco419 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_418: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco418 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_417: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco417 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_416: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco416 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_415: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco415 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_414: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco414 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_413: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco413 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_412: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco412 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_411: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco411 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_410: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco410 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_41: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco41 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_409: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco409 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_408: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco408 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_407: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco407 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_406: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco406 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_405: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco405 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_404: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco404 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_403: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco403 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_402: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco402 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_401: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco401 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_400: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco400 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_40: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco40 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_399: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco399 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_398: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco398 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_397: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco397 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_396: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco396 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_395: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco395 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_394: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco394 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_393: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco393 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_392: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco392 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_391: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco391 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_390: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco390 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_389: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco389 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_388: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco388 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_387: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco387 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_386: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco386 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_385: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco385 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_384: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco384 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_383: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco383 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_382: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco382 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_381: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco381 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_380: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco380 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_379: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco379 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_378: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco378 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_377: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco377 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_376: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco376 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_375: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco375 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_374: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco374 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_373: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco373 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_372: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco372 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_371: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco371 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_370: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco370 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_369: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco369 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_368: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco368 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_367: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco367 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_366: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco366 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_365: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco365 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_364: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco364 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_363: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco363 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_362: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco362 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_36: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco36 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_329: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco329 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_319: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco319 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_317: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco317 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_314: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco314 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_313: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco313 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_309: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco309 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_308: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco308 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_303: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco303 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_301: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco301 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_298: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco298 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_294: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco294 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_291: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco291 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_288: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco288 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_283: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco283 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_28: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco28 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_276: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco276 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_271: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco271 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_264: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco264 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_259: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco259 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_254: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco254 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_252: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco252 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_25: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco25 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_248: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco248 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_245: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco245 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_244: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco244 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_241: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco241 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_238: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco238 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_235: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco235 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_230: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco230 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_225: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco225 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_222: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco222 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_217: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco217 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_215: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco215 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_210: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco210 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_21: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco21 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_208: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco208 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_205: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco205 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_201: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco201 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_199: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco199 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_196: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco196 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_190: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco190 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_188: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco188 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_186: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco186 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_180: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco180 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_173: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco173 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_17: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco17 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_167: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco167 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_163: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco163 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_159: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco159 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_158: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco158 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_152: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco152 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_151: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco151 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_147: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco147 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_146: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco146 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_141: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco141 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_140: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco140 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_134: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco134 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_129: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco129 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_120: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco120 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_12: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco12 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_11: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco11 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_109: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco109 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_105: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco105 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_102: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco102 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MESSAGE_CENTER_ICO_101: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MessageCenterIco101 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MENU: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Menu {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MEDIA: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Media {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MEDAL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Medal {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.MATCH_LIVE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <MatchLive {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LIVE_HB: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Live_hb {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LIVE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Live {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LIST_MENU: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ListMenu {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LINK_EXTERNAL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LinkExternal {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LIGHT_MODE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LightMode {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_TRIS_LIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaTrisLight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_TRIS_DARK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaTrisDark {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_TQQ_LIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaTqqLight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_TQQ_DARK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaTqqDark {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_TOT_LIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaTotLight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_TOT_DARK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaTotDark {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_INPAGAMENTO_LIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaInpagamentoLight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_INPAGAMENTO_DARK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaInpagamentoDark {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_INATTESA_LIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaInattesaLight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_INATTESA_DARK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaInattesaDark {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_CHIUSA_LIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaChiusaLight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_CHIUSA_DARK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaChiusaDark {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_APERTA_LIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaApertaLight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_APERTA_DARK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaApertaDark {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_ANNULLATA_LIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaAnnullataLight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LEGENDA_ANNULLATA_DARK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LegendaAnnullataDark {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.LAPTOP_MAC: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <LaptopMac {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ITALIA: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Italia {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.INFO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Info {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.INFO_YELLOW: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <InfoYellow {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.INFO_SOLID: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <InfoSolid {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.IMAGE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Image {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.HEART_OUTLINE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <HeartOutline {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.HAPPYBET_LOGO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <HappybetLogo {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.GOTO_ARROW: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <GotoArrow {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.GIFT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Gift {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.GEAR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Gear {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.GAME_SLASH: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <GameSlash {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.GAME_AUTOEXCLUSION: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <GameAutoexclusion {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.FULL_PINNED: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <FullPinned {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.FULL_PIN: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <FullPin {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.FORM: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Form {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.FIORI: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Fiori {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.FILTER: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Filter {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.FILTER_VERTICAL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <FilterVertical {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.FERRI_NONE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <FerriNone {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.FERRI_FULL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <FerriFull {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.FERRI_DIETRO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <FerriDietro {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.FERRI_DAVANTI: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <FerriDavanti {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.EYE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Eye {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.EYE_OFF: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <EyeOff {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.EXIT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Exit {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.EXCLAMATION_CIRCLE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ExclamationCircle {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ECLAMATION_MARK_OUTLINE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <EclamationMarkOutline {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DUPLICATE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Duplicate {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DOWNLOAD: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Download {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DOWNLOAD_VIRTUAL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DownloadVirtual {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DOWNLOAD_APPLE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DownloadApple {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DOUBLE_CHEVRON_RIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DoubleChevronRight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_TIRO_CON_VOLO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DisciplinaTiroConVolo {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_SPORT_GAELICI: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DisciplinaSportGaelici {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_PATTINAGGIO_FIGURATO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DisciplinaPattinaggioFigurato {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_MANIFESTAZIONI_SPETTACOLO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DisciplinaManifestazioniSpettacolo {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_HOCKEY_INLIINE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DisciplinaHockeyInliine {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_FALLBACK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DisciplinaFallback {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_ESPORTS_WORLD_OF_WARCRAFT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DisciplinaEsportsWorldOfWarcraft {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_ESPORTS_STARCRAFT_2: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DisciplinaEsportsStarcraft2 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_ESPORTS_NBA2K_LEAGUE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DisciplinaEsportsNBA2KLeague {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_ESPORTS_MOTOCICLISMO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DisciplinaEsportsMotociclismo {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_ESPORTS_FORMULA_E: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DisciplinaEsportsFormulaE {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_99: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina99 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_98: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina98 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_97: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina97 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_96: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina96 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_95: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina95 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_94: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina94 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_93: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina93 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_92: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina92 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_91: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina91 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_90: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina90 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_9: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina9 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_89: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina89 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_88: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina88 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_87: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina87 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_86: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina86 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_85: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina85 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_83: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina83 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_82: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina82 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_81: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina81 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_80: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina80 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_8: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina8 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_79: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina79 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_78: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina78 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_77: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina77 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_76: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina76 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_75: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina75 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_73: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina73 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_71: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina71 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_70: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina70 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_7: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina7 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_69: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina69 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_68: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina68 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_67: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina67 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_66: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina66 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_65: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina65 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_64: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina64 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_62: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina62 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_61: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina61 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_60: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina60 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_6: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina6 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_59: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina59 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_58: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina58 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_57: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina57 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_56: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina56 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_55: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina55 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_54: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina54 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_53: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina53 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_52: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina52 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_51: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina51 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_50: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina50 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_5: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina5 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_49: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina49 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_48: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina48 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_47: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina47 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_46: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina46 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_45: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina45 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_44: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina44 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_43: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina43 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_42: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina42 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_41: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina41 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_40: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina40 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_39: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina39 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_38: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina38 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_37: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina37 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_36: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina36 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_35: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina35 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_34: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina34 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_33: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina33 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_32: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina32 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_31: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina31 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_30: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina30 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_3: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina3 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_29: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina29 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_28: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina28 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_27: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina27 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_24: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina24 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_23: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina23 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_22: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina22 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_21: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina21 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_20: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina20 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_2: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina2 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_19: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina19 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_18: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina18 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_17: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina17 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_16: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina16 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_159: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina159 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_158: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina158 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_157: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina157 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_156: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina156 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_155: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina155 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_154: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina154 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_152: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina152 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_150: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina150 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_15: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina15 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_148: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina148 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_147: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina147 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_144: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina144 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_143: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina143 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_142: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina142 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_140: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina140 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_14: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina14 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_138: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina138 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_136: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina136 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_135: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina135 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_134: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina134 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_132: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina132 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_131: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina131 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_130: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina130 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_13: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina13 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_129: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina129 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_128: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina128 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_127: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina127 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_126: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina126 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_125: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina125 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_124: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina124 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_123: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina123 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_121: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina121 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_120: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina120 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_12: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina12 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_119: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina119 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_118: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina118 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_117: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina117 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_116: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina116 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_115: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina115 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_114: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina114 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_112: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina112 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_110: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina110 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_11: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina11 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_109: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina109 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_108: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina108 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_107: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina107 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_106: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina106 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_105: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina105 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_104: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina104 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_103: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina103 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_1028: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina1028 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_1027: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina1027 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_1026: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina1026 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_102: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina102 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_1013: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina1013 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_101: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina101 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_1007: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina1007 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_1004: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina1004 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_1003: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina1003 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_1001: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina1001 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_100: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina100 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_10: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina10 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_1: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina1 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_03: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina03 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_02: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina02 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_01: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina01 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_003: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina003 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_001: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina001 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DISCIPLINA_00: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Disciplina00 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DICES: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Dices {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DEPOSIT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Deposit {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DASHBOARD: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Dashboard {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DASHBOARD_UPDATED: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DashboardUpdated {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.DARK_MODE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <DarkMode {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CUP: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Cup {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CUORI: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Cuori {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CONTROLLER_OFF: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ControllerOff {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CONTRACT_PDF: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ContractPdf {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.COMMENT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Comment {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CLOSE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Close {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CLOSE_CIRCLE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <CloseCircle {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CIRCLE_WARNING: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <CircleWarning {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CHEWRON_UP: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ChewronUp {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CHEWRON_RIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ChewronRight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CHEWRON_LEFT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ChewronLeft {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CHEWRON_DOWN: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ChewronDown {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CHECKMARK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Checkmark {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CHECK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Check {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CHECK_GREEN: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <CheckGreen {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CHAT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Chat {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CHART: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Chart {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CAVALLO: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Cavallo {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CAVALLO_PARAOCCHI: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <CavalloParaocchi {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CASH_MULTIPLE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <CashMultiple {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CANCEL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Cancel {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CANCEL_FULL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <CancelFull {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CALENDAR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Calendar {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CALCULATOR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Calculator {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.CALCULATOR_MD: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <CalculatorMd {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.BONUS: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Bonus {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.BONUS_GREY: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <BonusGrey {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.AWESOME_USER: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <AwesomeUser {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.AVATAR: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Avatar {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.AUTOESCLUSION_24H: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Autoesclusion24h {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ARROW: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Arrow {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ARROW_UP: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ArrowUp {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ARROW_INCREASE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ArrowIncrease {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ARROW_INCREASE_LIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ArrowIncreaseLight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ARROW_DECREASE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ArrowDecrease {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ARROW_DECREASE_LIGHT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ArrowDecreaseLight {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ARROW_BACK: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <ArrowBack {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ARCHIVE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Archive {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ALERT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Alert {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ADM_WHITE_HORIZONTAL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <AdmWhiteHorizontal {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ADM_WHITE_18: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <AdmWhite18 {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ADM_COLORFUL_HORIZONTAL: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <AdmColorfulHorizontal {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ACME: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Acme {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    case IconsEnum.ACCOUNT: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Account {...(oth as SVGProps<SVGSVGElement>)} />;
    }
    default: {
      return (
        <svg
          role="img"
          aria-label="fallback"
          className="snai-image snai-image-fallback"
          {...(oth as SVGProps<SVGSVGElement>)}
        />
      );
    }
  }
};
