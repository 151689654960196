/*****************************************************************************************

    FILE GENERATE AUTOMAGICALLY READING "CONFIG" from CMS

    N.B.: take care to keep aligned:
        //-> ConfigurationType
        //-> query result's type
        //-> defaults.config defined in common/tasks/config/index.js file

*****************************************************************************************/

import { ConfigurationType } from './types';

export const initialState: ConfigurationType = {
  modalTutorial: null,
  isActiveIppica: true,
  isActiveVirtual: true,
  webSocketInterval: 0,
  isActiveBingoLive: true,
  isNewRicaricaPreleva: true,
  isSlotTracingEnabled: false,
  isInstantScriptLoaded: false,
  isEnabledTrace: false,
  isActiveAvatars: false,
  betReferralTiming: 15,
  staticsUpdateTime: 1,
  isActiveMarcatori: false,
  isActiveStreaming: true,
  sportPacketMessage: true,
  isSportSearchEnabled: null,
  transferToCustomercard: false,
  transferFromCustomercard: false,
};
