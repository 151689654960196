import { RootState } from 'lib/centralStore';
import { createSelector } from '@reduxjs/toolkit';
import { isTruthy } from 'utility/functions';

const _self = (state: RootState) => state.configuration ?? {};

export const selectModalTutorial = createSelector([_self], ({ modalTutorial }) => modalTutorial);
export const selectIsEnabledTrace = createSelector([_self], ({ isEnabledTrace }) => isTruthy(isEnabledTrace));
export const selectIsActiveIppica = createSelector([_self], ({ isActiveIppica }) => isTruthy(isActiveIppica));
export const selectIsActiveVirtual = createSelector([_self], ({ isActiveVirtual }) => isTruthy(isActiveVirtual));
export const selectIsActiveAvatars = createSelector([_self], ({ isActiveAvatars }) => isTruthy(isActiveAvatars));
export const selectWebSocketInterval = createSelector([_self], ({ webSocketInterval }) => webSocketInterval ?? 0);
export const selectBetReferralTiming = createSelector([_self], ({ betReferralTiming }) => betReferralTiming ?? 15);
export const selectIsActiveMarcatori = createSelector([_self], ({ isActiveMarcatori }) => isTruthy(isActiveMarcatori));
export const selectIsActiveStreaming = createSelector([_self], ({ isActiveStreaming }) => isTruthy(isActiveStreaming));
export const selectIsActiveBingoLive = createSelector([_self], ({ isActiveBingoLive }) => isTruthy(isActiveBingoLive));
export const selectSportPacketMessage = createSelector([_self], ({ sportPacketMessage }) =>
  isTruthy(sportPacketMessage)
);
export const selectInstantScriptLoaded = createSelector([_self], ({ isInstantScriptLoaded }) =>
  isTruthy(isInstantScriptLoaded)
);
export const selectIsNewRicaricaPreleva = createSelector([_self], ({ isNewRicaricaPreleva }) =>
  isTruthy(isNewRicaricaPreleva)
);
export const selectIsSportSearchEnabled = createSelector([_self], ({ isSportSearchEnabled }) =>
  isTruthy(isSportSearchEnabled)
);
export const selectTransferToCustomerCard = createSelector([_self], ({ transferToCustomercard }) =>
  isTruthy(transferToCustomercard)
);
export const selectTransferFromCustomerCard = createSelector([_self], ({ transferFromCustomercard }) =>
  isTruthy(transferFromCustomercard)
);
