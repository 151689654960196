import { DefaultHttpClient, HttpRequest, HttpResponse } from '@microsoft/signalr';

import { SIGNALR_RECONNECTION_MAX_ATTEMPTS } from 'utility/constant';
import { getUseSecondary } from 'lib/mwNodeSwitch';

const attempts: { [key: string]: number } = {};

export class CustomHttpClient extends DefaultHttpClient {
  constructor() {
    super(console);
  }

  public send(request: HttpRequest): Promise<HttpResponse> {
    const key = decodeURIComponent(`${request.url}`);
    let counter = attempts[key] ?? 0;

    if (counter > SIGNALR_RECONNECTION_MAX_ATTEMPTS) {
      throw new Error(`Max connection attempts reached for ${key}`);
    }

    const head = getUseSecondary();
    if (head) {
      const headers = request.headers ?? {};
      Reflect.set(headers, head.name, head.value);
      request.headers = headers;
    }

    // increase attempt counter
    attempts[key] = counter + 1;
    const result = super.send(request);

    result.then(() => {
      // on success reset counter
      attempts[key] = 0;
    });

    return result;
  }
}
