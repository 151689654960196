import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ConfigurationType } from './types';
import { getConfig } from './actions';
import { initialState } from './initialState';

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setInstantScriptLoaded: (state, action: PayloadAction<boolean>) => {
      state.isInstantScriptLoaded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConfig.fulfilled, (state, { payload }: PayloadAction<ConfigurationType>) => {
      for (let key of Object.keys(payload ?? {})) {
        const value = Reflect.get(payload, key);
        Reflect.set(state, key, value);
      }
    });
  },
});

export const { setInstantScriptLoaded } = configurationSlice.actions;

export default configurationSlice.reducer;
