import { useEffect, useState } from 'react';

import { isMatch } from 'utility/functions';
import { useRouter } from 'next/router';

const KEYTOSEARCH = 'seamless';

export const useIsSeamlessMode = () => {
  const { asPath } = useRouter();
  const [isSeamlessMode, setIsSeamlessMode] = useState<boolean>(false);

  useEffect(() => {
    const [path, search] = asPath.split('?');
    if (isMatch(path, `\/${KEYTOSEARCH}(\/|-)`)) {
      setIsSeamlessMode(true);
    } else {
      const keyValuePairs = search?.split('&') ?? [];
      const seamless = keyValuePairs.some((keyValuePair) => isMatch(keyValuePair, `^${KEYTOSEARCH}(=|$)`));
      setIsSeamlessMode(seamless);
    }
  }, [asPath]);

  return isSeamlessMode;
};
